import { route } from "../config";
import {motion} from "framer-motion"
import { background_popup, image_viewer } from "../animations/tools";




export default function Viewer(props) {
    return (
        <motion.div

            exit = "exit"
            animate = "animate" 
            initial = "initial"
            variants = {background_popup}
            className="viewer" onClick = {() => props.setViewer('')}
            
        >
            <motion.img 
                exit = "exit"
                animate = "animate" 
                initial = "initial"
                variants = {image_viewer}
                src = {route(props.viewer)} alt = "View"
                
            />
                
        </motion.div>
    )
}