import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { AnimatePresence } from 'framer-motion'
import {v4} from 'uuid'
import { route } from '../config'
import StatusRequest from '../tools/StatusRequest'
import Homepage from './homepage'
import Session from './session'
import Loader from '../tools/Loader'
import Viewer from '../tools/Viewer'

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return { day : day, month : month, year : year};
}

export default function Main() {

    /*
    --> Purpose : Connection / Disconnection
    [MODE, SETMODE] - Boolean :
        - true : AccountConnected 
        - false : Login / Registerx

                    day : parseInt(formatDate(new Date()).day).toString(), 
            month : formatDate(new Date()).month, 
            year : formatDate(new Date()).year

            day : '8', 
            month : '12', 
            year : "2022"
    */
    const [dataStatus, setDataStatus] = useState(false)
    const [loader, setLoader] = useState(false)
    const [viewer, setViewer] = useState('')
    const [statusRequest, setStatusRequest] = useState({

    })
    const [config, setConfig] = useState({
        test : true, 
        currentDate : {
            day : parseInt(formatDate(new Date()).day).toString(), 
            month : formatDate(new Date()).month, 
            year : formatDate(new Date()).year
        }, 

        
        /*currentDate : {
            day : '25', 
            month : '12', 
            year : "2022"
        },*/
        token : ""
    })

    //console.log(v4())

    useEffect(() => {
        //WHEN THERE IS TOKEN
        if(config.token.length === 0 && localStorage.getItem('token')) {
            setLoader(true)
            const token = localStorage.getItem('token')
            axios.post(route('login/verification'), {token : token})
            .then(res => {
                //console.log(res.data)
                setConfig(state => {
                    return {
                        ...state, 
                        token : token, 
                        account : res.data.account
                    }
                })
                setLoader(false)
    
                return setStatusRequest({
                    status : 1, 
                    message : "Vous êtes connecté avec succès."
                })

    
            })
            .catch(err => { 
                console.log(err)
                localStorage.clear()
            })
        }

        //INITIALIZE DATA
        if(!dataStatus && config.token.length > 0) {
            setLoader(true)
            axios.get(route('initialize/'))
            .then(res => {

                setConfig(state => {
                    return {
                        ...state, 
                        dataGame : res.data
                    }
                })
                setLoader(false)
                
            })
            .catch(err => { 
                console.log(err)
                
                setLoader(false)
            })
            setDataStatus(true)
        }



    }, [config.token])

    
    //console.log(config)
    return (
        <React.Fragment>
            <AnimatePresence>
            <StatusRequest 
                statusRequest={statusRequest}
                setStatusRequest = {setStatusRequest}

            
            />
            </AnimatePresence>

            <AnimatePresence>
                {
                    viewer.length > 0 ? 
                        <Viewer
                            viewer= {viewer}
                            setViewer = {setViewer}
                        />
                    : 
                        null
                }

            </AnimatePresence>

            {
                loader ? 
                    <Loader />
                : 

                    null
            }


            {
                config.token.length > 0 ? 
                    <Session 
                        setLoader = {setLoader}
                        loader = {loader}
                        config = {config}
                        setConfig = {setConfig}
                        setStatusRequest = {setStatusRequest}
                        setViewer = {setViewer}
                        handleDisconnect = {() => {
                            setConfig({
                                currentDate : formatDate(new Date()),
                                token : ""
                            })
                            setStatusRequest({
                                status : 1, 
                                message : "Vous êtes déconnecté."
                            })
                            localStorage.clear()
                        }}
                    />
                : 
                    <Homepage 
                        setLoader = {setLoader}
                        loader = {loader}
                        setConfig = {setConfig}
                        setStatusRequest = {setStatusRequest}
                    />
            }
        </React.Fragment>
        

    )
}