import React, {useState} from 'react'
import {motion} from 'framer-motion'
import {ReactComponent as Logo} from '../../assets/logo.svg' ; 
import {ReactComponent as Branche1} from '../../assets/branche_1.svg' ; 
import {ReactComponent as Branche2} from '../../assets/branche_2.svg' ; 
import { branche_1_homepage, branche_2_homepage, buttons_homepage, logo_homepage, text_homepage } from '../../animations/homepage';
import Register from './layouts/Register';
import Login from './layouts/Login';


export default function Homepage(props) {
    const [mode, setMode] = useState('homepage')

    return (
        
        <div className="homepage">

            {
                //HOMEPAGE TOP
                mode === "homepage" ? 
                    <motion.div 
                        exit = "exit"
                        animate = "animate" 
                        initial = "initial"
                        variants = {logo_homepage}
                        className='homepage__logo'
                    >
                        <Logo />
                    </motion.div>
                : 
                    null
            }

            
            <div 
                className={`homepage__presentation${mode === "homepage" ? "" : mode === "register" ? "--register" : "--login"}`}
            >
                {
                    //BRANCHE 1
                    mode === "homepage" ? 
                        <motion.div 
                        key = {'BB2'}
                        className='branche'
                        exit = "exit"
                        animate = "animate" 
                        initial = "initial"
                        variants = {branche_1_homepage}
                        >
                                    <Branche1 />

        
                        </motion.div>
                    : 
                        <motion.div 
                        key = {'BB1'}
                        className='branche'
                        exit = "exit"
                        animate = "animate" 
                        initial = "initial"
                        variants = {branche_1_homepage}
                        >
                                    <Branche2 />

        
                        </motion.div>

                }



                

                <motion.div 
                    exit = "exit"
                    animate = "animate" 
                    initial = "initial"
                    variants = {text_homepage}
                    className='text'>
                        Le Calendrier de l'Avent
                </motion.div>
                {
                    //BRANCHE 2
                    mode === "homepage" ? 
                        <motion.div 
                        key = {'BB3'}
                        className='branche'
                        exit = "exit"
                        animate = "animate" 
                        initial = "initial"
                        variants = {branche_2_homepage}
                        >
                                    <Branche1 />

        
                        </motion.div>
                    : 
                        <motion.div 
                        key = {'BB4'}
                        className='branche'
                        exit = "exit"
                        animate = "animate" 
                        initial = "initial"
                        variants = {branche_2_homepage}
                        >
                                    <Branche2 />

        
                        </motion.div>

                }


            </div>

            {
                mode === "homepage" ? 
                    <motion.div 
                        exit = "exit"
                        animate = "animate" 
                        initial = "initial"
                        variants = {buttons_homepage}
                        className='homepage__buttons'
                    >
                    {/*
                        <motion.button
                        whileTap={{scale : 0.9}}
                        whileHover={{scale : 1.05}}
                        style = {{}}
                        onClick = {() => setMode('register')}
                    >
                        Inscription
                    </motion.button>
                    */}
                    <motion.button
                        whileTap={{scale : 0.9}}
                        whileHover={{scale : 1.05}}
                        onClick = {() => setMode('login')}
                    >
                        Connexion
                    </motion.button>
                    </motion.div>
                : 
                    null
            }







            {
                // REGISTER
                mode === "register" ? 
                    <Register 
                        setMode = {setMode}
                        setStatusRequest = {props.setStatusRequest}
                        setLoader = {props.setLoader}
                    />
                : 
                    null
            }

            {
                mode === "login" ? 
                    <Login 
                        setMode = {setMode}
                        setConfig = {props.setConfig}
                        setStatusRequest = {props.setStatusRequest}
                        setLoader = {props.setLoader}
                    />
                : 
                    null
            }
        </div>
    )
}


//    Click button register                 onClick = {() =>/*() => setMode('register')*/}