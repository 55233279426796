import axios from 'axios';
import React, {useState, useEffect} from 'react'
import { AnimatePresence, motion } from 'framer-motion';
import { route } from '../../config';
import Popup from '../../tools/Popup'
import { staffSupp } from './assets/data';
import {ReactComponent as Arrow} from '../../assets/arrow.svg'
import {ReactComponent as Crown2} from '../../assets/crown_2.svg'
import { childpic__gameplay, crown__gameplay } from '../../animations/tools';
import { response_twentyFour, text_twentyFour, title_twentyFour } from '../../animations/twentyFour';
import {ReactComponent as CheckSVG} from '../../assets/check.svg'
import {ReactComponent as User} from '../../assets/user_default.svg'

function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      let temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
}

export default function TwentyFour(props) {
    const [steps, setSteps] = useState(0)
    const [response, setResponse] = useState(props.config.account.twentyFour)
    const [staff, setStaff] = useState([])


    useEffect(() => {
        if(staff.length === 0) {
            axios.get(route('game/users'))
            .then(res => {

                const getData = res.data.filter(element => !staffSupp.filter(element => element.idUser).map(element => element.idUser).includes(element.idUUID))

                setStaff(shuffle([
                    ...staffSupp, 
                    ...getData
                ]))
    

    
            })
            .catch(err => {
                console.log(err)
            })
        }

    }, [staff])

    const handlePrev = () => {
        if(steps > 0) {
            setSteps(steps - 1)
        }
    }

    const handleNext = () => {
        if(steps < staff.filter(element => element.image).length - 1) {
            if(!staff.map(element => !element.name ? `${element.firstname} ${element.lastname}` : element.name).includes(response.find(element => element.idUUID === staff.filter(element => element.image)[steps].idUUID).response)) {
                return props.setStatusRequest({
                    status : 2, 
                    message : "Assignez un nom à la photo pour continuer."
                })
            }
            
            return setSteps(steps + 1)
        }
    }

    const filterList = (tabUser) => {
        const getNames = tabUser.map(element => !element.name ? {...element, name :`${element.firstname} ${element.lastname}`} : {...element})

        if(getNames.find(element => element.name === response.find(element => element.idUUID === staff.filter(element => element.image)[steps].idUUID).response)) {
            return []
        }

        return getNames.filter(element => element.name.trim().toLowerCase().replace('é', "e").match(response.find(element => element.idUUID === staff.filter(element => element.image)[steps].idUUID).response.trim().toLowerCase())).sort((a,b) => a.name - b.name)
    }

    const handleChangeInput = (e) => {
        const getNames = staff.map(element => !element.name ? {...element, name :`${element.firstname} ${element.lastname}`} : {...element})

        const value = e.target.value

        if(getNames.find(element => element.name === response.find(element => element.idUUID === staff.filter(element => element.image)[steps].idUUID).response)) {
            return setResponse(response.map(element => element.idUUID === staff.filter(subEl => subEl.image)[steps].idUUID ? {...element, response : ''} : element))
        }

        return setResponse(response.map(element => element.idUUID === staff.filter(subEl => subEl.image)[steps].idUUID ? {...element, response : value} : element))
    }

    const handleSelect = (user) => {
        setResponse(response.map(element => element.idUUID === staff.filter(subEl => subEl.image)[steps].idUUID ? {...element, response : user.name} : element))
    }

    const handleSubmit = () => {
        props.setLoader(true)
        axios.post(route('game/twentyFour'), {idUUID : props.config.account.idUUID, response : JSON.stringify(response)})
        .then(res => {
            props.setLoader(false)
            props.setStatusRequest(res.data)
            props.setConfig(state => {
                return {
                    ...state, 
                    account : {
                        ...state.account, 
                        twentyFour : response
                    }
                }
            })
            props.handleClose()
        })
        .catch(err => {
            props.setLoader(false)
        })
    }

    /*

    Response : 

    response.find(element => element.idUUID === staff.filter(element => element.image)[steps].idUUID).response

    staff.map(element => !element.name ? `${element.firstname} ${element.lastname}` : element.name).includes

    */





    return (
        <Popup
            handleClose = {props.handleClose}
        >
            <div className='twentyFour'>

                {
                    staff.length > 0 ? 
                        <React.Fragment>
                            <motion.h1
                            exit = "exit"
                            animate = "animate" 
                            initial = "initial"
                            variants = {title_twentyFour}
                            >
                                Jour 24
                            </motion.h1>
                            <motion.p
                                exit = "exit"
                                animate = "animate" 
                                initial = "initial"
                                variants = {text_twentyFour}
                            >
                                <span>Assigne un nom aux {staff.filter(element => element.image).length} photos d'enfance pour valider la boite du 24 décembre 2023.</span>
                            </motion.p>
                            <div className='twentyFour__pic'>
                                <motion.div 
                                    exit = "exit"
                                    animate = "animate" 
                                    initial = "initial"
                                    variants = {crown__gameplay}
                                    className='crown'
                                    key = {steps}
                                >
                                    <Crown2 />
                                </motion.div>

                                <AnimatePresence>

                                <motion.div 
                                    exit = "exit"
                                    animate = "animate" 
                                    initial = "initial"
                                    variants = {childpic__gameplay}
                                    className='pic'                                    
                                    key = {steps}
                                    onClick = {() => props.setViewer(`images/supplement/${staff.filter(element => element.image)[steps].idUUID.trim()}.jpg`)}
                                >
                                    <img src = {staff.filter(element => element.image)[steps].image} alt = {`childpic`} />

                                </motion.div>
                                </AnimatePresence>
                            </div>





                            {
                                steps > 0 ? 
                                <motion.div 
                                whileTap={{left : '10px'}}
                                className='arrow arrow__left'
                                onClick = {handlePrev}
                                >
                                    <Arrow />
                                </motion.div>

                                : 
                                    null
                            }

                            {
                                steps < staff.filter(element => element.image).length - 1 ? 
                                    <motion.div 
                                    whileTap={{right : '10px'}}
                                    className='arrow arrow__right'
                                    onClick = {handleNext}
                                    >
                                        <Arrow />
                                    </motion.div>
                                : 
                                    null
                            }




                            {

                            }

                            {
                                response.find(element => element.idUUID === staff.filter(element => element.image)[steps].idUUID) ? 
                                    <motion.div 
                                    className='response'
                                    exit = "exit"
                                    animate = "animate" 
                                    initial = "initial"
                                    variants = {response_twentyFour}
                                
                                >
                                    <label htmlFor=''>Votre réponse</label>
                                    <div
                                        className='response__chooser'
                                    >
                                        <input 
                                            key = {steps}
                                            type = "text" 
                                            placeholder = "Écrivez son nom ici"
                                            value = {response.find(element => element.idUUID === staff.filter(element => element.image)[steps].idUUID).response}
                                            onChange = {handleChangeInput}
                                        />

                                        {
                                            response.find(element => element.idUUID === staff.filter(element => element.image)[steps].idUUID).response.length > 0 ? 
                                                <div>
                                                {
                                                    filterList(staff).length > 0  ? 
                                                    filterList(staff).map((element, position) => (
                                                        <div
                                                            key = {`${JSON.stringify(element)}`}
                                                            onClick = {() => handleSelect(element)}
                                                        >
                                                            {
                                                                element.firstname ? 
                                                                    <React.Fragment>
                                                                        <span>{element.firstname.toLowerCase()}</span>
                                                                        &nbsp;
                                                                        <span>{element.lastname.toLowerCase()}</span>
                                                                    </React.Fragment>
                                                                : 
                                                                    element.name
                                                            }

                                                        </div>
                                                    ))
                                                    : 
                                                        response.filter(el2 => staff.map(element => !element.name ? `${element.firstname} ${element.lastname}` : element.name).includes(el2.response)).length !== staff.filter(element => element.image).length ? 
                                                            <div className='noData'>
                                                                <User />
                                                                <p>Aucun utilisateur n'a été trouvé</p>
        
                                                            </div>
                                                        : 
                                                            null

                                                }
                                                </div>
                                            : 
                                            null
                                        }

                                    </div>

                                    </motion.div>

                                :  

                                    null
                            }

                            {
                                response.filter(el2 => staff.map(element => !element.name ? `${element.firstname} ${element.lastname}` : element.name).includes(el2.response)).length === staff.filter(element => element.image).length ? 
                                    JSON.stringify(response) === JSON.stringify(props.config.account.twentyFour) ? 
                                        <p className='saved'>
                                            <CheckSVG />
                                            Les réponses suivantes ont bien été enregistrées !
                                        </p>
                                    : 
                                        null
                                : 
                                    null

                            }


                            <motion.button
                                className={
                                    response.filter(el2 => staff.map(element => !element.name ? `${element.firstname} ${element.lastname}` : element.name).includes(el2.response)).length === staff.filter(element => element.image).length ? 
                                        "submit submit__success"
                                    : 
                                        "submit submit__pending"
                                }

                                onClick={() =>
                                    response.filter(el2 => staff.map(element => !element.name ? `${element.firstname} ${element.lastname}` : element.name).includes(el2.response)).length === staff.filter(element => element.image).length ? 
                                        handleSubmit()
                                    : 
                                        null
                                }

                                style = {{
                                    opacity : JSON.stringify(response) === JSON.stringify(props.config.account.twentyFour) ? '0' : response.filter(el2 => staff.map(element => !element.name ? `${element.firstname} ${element.lastname}` : element.name).includes(el2.response)).length === staff.filter(element => element.image).length ? "1" : '0.6'
                                }}
                            >
                                {
                                    response.filter(el2 => staff.map(element => !element.name ? `${element.firstname} ${element.lastname}` : element.name).includes(el2.response)).length === staff.filter(element => element.image).length ? 
                                        `Valider les réponses`
                                    
                                    : 
                                    `${response.filter(el2 => staff.map(element => !element.name ? `${element.firstname} ${element.lastname}` : element.name).includes(el2.response)).length}/${staff.filter(element => element.image).length} 
                                    photo${response.filter(el2 => staff.map(element => !element.name ? `${element.firstname} ${element.lastname}` : element.name).includes(el2.response)).length > 1 ? "s" : ""}
                                    complétée${response.filter(el2 => staff.map(element => !element.name ? `${element.firstname} ${element.lastname}` : element.name).includes(el2.response)).length > 1 ? "s" : ""}`
                                }


                            </motion.button>
                        </React.Fragment>
                    : 
                        null
                    //
                }


            </div>
        </Popup>
    )
}
