import React, {useState, useEffect} from 'react'


export default function Timer() {

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
  
    const deadline = "December, 1, 2023";
    const time = Date.parse(deadline) - Date.now();   
    
    const getTime = () => {
        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
      };

    useEffect(() => {
        const interval = setInterval(() => getTime(deadline), 1000);

        return () => clearInterval(interval);
    }, [time]);



    return (
        <div className='timer'>
            <div>
                <p>Jours</p>
                <p>{days < 10 ? "0" + days : days}</p>
            </div>
            <div>
                <p>Heur.</p>
                <p>{hours < 10 ? "0" + hours : hours}</p>
            </div>
            <div>
                <p>Min.</p>
                <p>{minutes < 10 ? "0" + minutes : minutes}</p>
            </div>
            <div>
                <p>Sec.</p>
                <p>{seconds < 10 ? "0" + seconds : seconds}</p>
            </div>
        </div>
    )
}