/*

exit = "exit"
animate = "animate" 
initial = "initial"
variants = {}

export const variable = {
    exit : {

    }, 
    animate : {

    }, 
    initial : {
        
    }
}

*/

// Homepage 
export const logo_homepage= {
    exit : {
        y : -50, 
        opacity : 0
    }, 
    animate : {
        y : 0, 
        opacity : 1,
        transition : {
            duration : 0.4
        }
    }, 
    initial : {
        y : -50, 
        opacity : 0
    }
}

export const branche_1_homepage= {
    exit : {
        x : -500, 
        opacity : 0, 
        scale : -1
    }, 
    animate : {
        x : 0, 
        opacity : 1,
        scale : -1, 
        transition : {
            duration : 0.8
        }
    }, 
    initial : {
        x : -500, 
        scale : -1, 
        opacity : 0
    }
}

export const branche_2_homepage= {
    exit : {
        x : 500, 
        opacity : 0
    }, 
    animate : {
        x : 0, 
        opacity : 1,
        transition : {
            duration : 0.8
        }
    }, 
    initial : {
        x : 500, 
        opacity : 0
    }
}

export const text_homepage= {
    exit : {
        scale : 0
    }, 
    animate : {
        scale : 1, 
        transition : {
            duration : 0.6
        }
    }, 
    initial : {
        scale : 0
    }
}

export const buttons_homepage= {
    exit : {
        y : 100, 
        opacity : 0
    }, 
    animate : {
        y : 0, 
        opacity : 1,
        transition : {
            duration : 0.6
        }
    }, 
    initial : {
        y : 100, 
        opacity : 0
    }
}

//Register

export const container_register = {
    exit : {
        y: 1000
    }, 
    animate : {
        y: 0, 
        transition : {
            type : "linear", 
            duration : 0.6
        }
    }, 
    initial : {
        y: 1000
    }
}

export const step_register = {
    exit : {
        x: 1000
    }, 
    animate : {
        x: 0, 
        transition : {
            type : "linear", 
        }
    }, 
    initial : {
        x: 1000
    }
}