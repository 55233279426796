import React, {useState} from 'react' ; 
import { AnimatePresence, motion } from 'framer-motion';
import {ReactComponent as Crown2} from '../assets/crown_2.svg'
import {ReactComponent as Cancel} from '../assets/cancel.svg'
import {ReactComponent as Arrow} from '../assets/arrow.svg'
import {ReactComponent as UserDefault} from '../assets/user_default.svg'
import {ReactComponent as TodayIsYou} from '../assets/today_is_you.svg'
import { route } from '../config';
import { background__gameplay, childpic__gameplay, choose_gameplay, crown__gameplay, slide_gameplay, submit_gameplay } from '../animations/tools';
import axios from 'axios';

export default function Gameplay(props) {


    const [choose, setChoose] = useState(
        props.accountResult.length > 0 ? 
            props.accountResult.find(element => element.idUUID === props.data.find(element => element.day === props.day).idUUID) ? 
                props.data.map((element, position) => element.idgamer === props.accountResult.find(element => element.idUUID === props.data.find(element => element.day === props.day).idUUID).response ? position : null).filter(element => element !== null)[0]
            : 
                0
        :
            0
    )


    //props.accountResult.find(element => element.idUUID === props.data.find(element => element.day === props.day).idUUID).response

    //2e3f350a-a529-4f2f-94cb-a6adc0de98b5
    


    const handlePrev = () => {
        if(choose > 0) {
            setChoose(choose - 1)
        }
    }

    const handleNext = () => {
        if(choose < props.data.length - 1) {
            setChoose(choose + 1)
        }
    }

    const handleSubmit = () => {
        /*
            BDD REP : props.config.other
            ID GAME : props.config.dataGame.find(element => element.day === props.config.currentDate.date).idUUID
        */
        
        props.setLoader(true)

        const result = {
            user : props.config.account.idUUID, 
            idUUID : props.data.find(element => element.day === props.day).idUUID, 
            response : props.data[choose].idgamer
        }

        axios.post(route('game/'), result)
        .then(res => {
            
            props.setConfig(state => {
                return {
                    ...state, 
                    account : {
                        ...state.account, 
                        results : res.data
                    }
                    
                }
            })
            props.setLoader(false)
            props.handleClose()
            
            props.setStatusRequest({
                status : 1, 
                message : "Votre choix a bien été enregisté"
            })
        })
        .catch(err => { 
            props.setLoader(false)
            if(!err.response) {
                return props.setStatusRequest({
                    status : 0, 
                    message : "L'accès au serveur ne peut pas se faire. Veuillez contacter l'administrateur."
                })
            }
            return props.setStatusRequest(err.response.data)
        })
        
        
    }

    //USER ID : props.config.account.idUUID
    //CURRENT DATE USER : 
    


    return (
        <motion.div 
            exit = "exit"
            animate = "animate" 
            initial = "initial"
            variants = {background__gameplay}
            className="gameplay"
        >
            <div className='gameplay__close'>
                <div onClick = {props.handleClose}>
                    <Cancel />
                </div>
            </div>
            <div className='gameplay__day'>
                Jour {props.day}
            </div>
            <div className='gameplay__childpic'>
                <motion.div 
                    exit = "exit"
                    animate = "animate" 
                    initial = "initial"
                    variants = {crown__gameplay}
                    className='crown'
                >
                    <Crown2 />
                </motion.div>
                <motion.div 
                    exit = "exit"
                    animate = "animate" 
                    initial = "initial"
                    variants = {childpic__gameplay}
                    className='pic'
                    onClick = {() => props.setViewer(props.data.find(element => element.day === props.day).childpic)}
                    >
                    <img src = {route(props.data.find(element => element.day === props.day).childpic)} alt = {`childpic`} />
                </motion.div>
            </div>
            <motion.div 
                className='gameplay__content'
            >
               {
                //YOUR DAY
                props.config.account.idUUID == props.data.find(element => element.day === props.day).idgamer ? 
                    <div className='todayYou'>
                        <TodayIsYou />
                    </div>
                : 

                    //CHOOSE CHILD PIC
                    props.currentDate === props.day ? 
                        <motion.div 
                            exit = "exit"
                            animate = "animate" 
                            initial = "initial"
                            variants = {choose_gameplay}
                            className='choose'
                            
                        >
                            <h2>Qui est-ce ?</h2>
                            <div className='choose__pic'>
                                <motion.div 
                                    whileHover={{scale : 1.05}}
                                    whileTap={{scale : 0.9}}
                                    className='arrow'
                                    onClick = {handlePrev}
                                    style = {{
                                        opacity : choose === 0 ? "0" : "1",
                                        cursor : choose === 0 ? "default" : "pointer"
                                    }}
                                
                                ><Arrow /></motion.div>
                                <div className='user'>
                                    <AnimatePresence>
                                    <motion.div
                                        exit = "exit"
                                        animate = "animate" 
                                        initial = "initial"
                                        variants = {slide_gameplay}
                                        key = {props.data[choose].recentpic}
                                        onClick = {() => props.setViewer(props.data[choose].recentpic)}
                                        className = {
                                            props.accountResult.length > 0 ? 
                                                props.accountResult.find(element => element.idUUID === props.data.find(element => element.day === props.day).idUUID) ? 
                                                    props.data[choose].idUUID === props.data.find(element => element.idgamer === props.accountResult.find(element => element.idUUID === props.data.find(element => element.day === props.day).idUUID).response).idUUID ? 
                                                        "active"
                                                    : 
                                                        ""
                                                : 
                                                    ""
                                            : 
                                                ""
                                        }
                                    >
                                        {
                                            props.data[choose].recentpic ? 
                                                <img src = {route(props.data[choose].recentpic)} alt = {`${props.data[choose].firstname} ${props.data[choose].lastname}`}/>
                                            : 
                                                <UserDefault />
                                        }

                                    </motion.div>
                                    </AnimatePresence>
                                    <p>
                                        <span>{props.data[choose].firstname.toLowerCase()} </span>
                                        <span>{props.data[choose].lastname.toLowerCase()}</span>
                                    </p>
                                </div>
                                <motion.div 
                                    whileHover={{scale : 1.05}}
                                    whileTap={{scale : 0.7}}
                                    className='arrow'
                                    onClick = {handleNext}
                                    style = {{
                                        opacity : choose === 23 ? "0" : "1",
                                        cursor : choose === 23 ? "default" : "pointer"
                                    }}
                                >
                                    <Arrow />
                                </motion.div>
                            </div>

                            <div className='button'>
                            <AnimatePresence>
                            {

                                    props.accountResult.length > 0 ? 
                                        props.accountResult.find(element => element.idUUID === props.data.find(element => element.day === props.day).idUUID) ? 
                                            props.data[choose].idUUID === props.data.find(element => element.idgamer === props.accountResult.find(element => element.idUUID === props.data.find(element => element.day === props.day).idUUID).response).idUUID ? 
                                                null
                                        : 
                                            <motion.button
                                            exit = "exit"
                                            animate = "animate" 
                                            initial = "initial"
                                            variants = {submit_gameplay} 
                                            whileHover = {{scale : 1.05}}
                                            whileTap = {{scale : 0.9}}
                                            onClick={() => handleSubmit()}
                                            >
                                                Valider
                                            </motion.button>
                                    : 
                                        <motion.button
                                            exit = "exit"
                                            animate = "animate" 
                                            initial = "initial"
                                            variants = {submit_gameplay} 
                                            whileHover = {{scale : 1.05}}
                                            whileTap = {{scale : 0.9}}
                                            onClick={() => handleSubmit()}
                                        >
                                            Valider
                                        </motion.button>
                                :
                                    <motion.button
                                    exit = "exit"
                                    animate = "animate" 
                                    initial = "initial"
                                    variants = {submit_gameplay} 
                                    whileHover = {{scale : 1.05}}
                                    whileTap = {{scale : 0.9}}
                                    onClick={() => handleSubmit()}
                                    >
                                        Valider
                                    </motion.button>
                            }
                            </AnimatePresence>
                            </div>


                        </motion.div>
                    : 
                                        //YOUR DAY

                        //RESULT
                        <div className='results'>
                            <h2>Cette personne était</h2>
                            <div className='user'>

                                <AnimatePresence>
                                <motion.div
                                    exit = "exit"
                                    animate = "animate" 
                                    initial = "initial"
                                    variants = {slide_gameplay}
                                    key = {props.data.find(element => element.day === props.day).recentpic}
                                    onClick = {() => props.setViewer(props.data.find(element => element.day === props.day).recentpic)}

                                >
                                    {
                                        props.data.find(element => element.day === props.day).recentpic ? 
                                            <img 
                                                src = {route(props.data.find(element => element.day === props.day).recentpic)} 
                                                alt = {`${props.data.find(element => element.day === props.day).firstname} ${props.data.find(element => element.day === props.day).lastname}`}
                                            />
                                        : 
                                            <UserDefault />
                                    }

                                </motion.div>
                                </AnimatePresence>
                                    <p>
                                        <span>{props.data.find(element => element.day === props.day).firstname.toLowerCase()} </span>
                                        <span>{props.data.find(element => element.day === props.day).lastname.toLowerCase()}</span>
                                    </p>

                            </div>
                            <div className={`results__final results__final__${
                                props.accountResult.length > 0 ? 
                                props.accountResult.find(element => element.idUUID === props.data.find(element => element.day === props.day).idUUID) ? 
                                    props.data.find(element => element.day === props.day).idgamer === props.accountResult.find(element => element.idUUID === props.data.find(element => element.day === props.day).idUUID).response ? 
                                        "win"
                                        
                            
                                    : 
                                        "loose"
                                        
                                : 
                                    "loose"
                            
                            : 
                                "loose"
                            }`}
                                
                            >
                                {
                                    props.accountResult.length > 0 ? 
                                    props.accountResult.find(element => element.idUUID === props.data.find(element => element.day === props.day).idUUID) ? 
                                        props.data.find(element => element.day === props.day).idgamer === props.accountResult.find(element => element.idUUID === props.data.find(element => element.day === props.day).idUUID).response ? 
                                            <React.Fragment>
                                                <h2>Bravo ! Vous avez gagné !</h2>
                                            </React.Fragment>
                                            
                                
                                        : 
                                        <React.Fragment>
                                            <p>Oh non ! Vous avez perdu !</p>
                                            <p>{`Votre réponse était : ${props.data.find(days => days.idgamer === props.accountResult.find(element => element.idUUID === props.data.find(element => element.day === props.day).idUUID).response).firstname}`}</p>
                                        </React.Fragment>
                                            
                                    : 
                                        <React.Fragment>
                                           <p>Oh non ! Vous avez perdu !</p>
                                           <p>Vous n'avez pas répondu. </p>
                                        </React.Fragment>
                                
                                : 
                                    <React.Fragment>
                                        <p>Oh non ! Vous avez perdu !</p>
                                        <p>Vous n'avez pas répondu. </p>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    
                 
                                
                    
               }
            </motion.div>
        </motion.div>
    )
}

/*

3 situations : 

results.length >
*/