import React from 'react' ; 
import { motion } from 'framer-motion';
import {ReactComponent as Cancel} from '../assets/cancel.svg' ; 
import { background_popup, buttons_popup, recaptcha__popup } from '../animations/tools';
import ReCAPTCHA from 'react-google-recaptcha'
import {v4} from 'uuid'


export default function Popup(props){
    return (
        <motion.div 
            className={props.nameClass ? `popup ${props.nameClass}` : 'popup'}
            exit = "exit"
            animate = "animate" 
            initial = "initial"
            variants = {background_popup}
        >
            <div className="popup__close">
                {
                    !props.removeCancel ? 
                        <motion.div 
                        exit = "exit"
                        animate = "animate" 
                        initial = "initial"
                        variants = {buttons_popup}
                        onClick = {props.handleClose}
                        ><Cancel /></motion.div>
                    : 
                        null
                }


            </div>
            <div className="popup__container">
                {props.children}
            </div>
        </motion.div>
    )
}

export function PopupRecaptcha(props) {
    /*
    
    */


    return (
        <motion.div 
            exit = "exit"
            animate = "animate" 
            initial = "initial"
            variants = {recaptcha__popup}
            className='popup__recaptcha'>
            <div className='popup__recaptcha__close' onClick = {props.handleClose}></div>
            <motion.div 
                className='popup__recaptcha__container'
            >
                <h1>Vérification</h1>
                <p>Merci de bien vouloir compléter ce recaptcha afin de savoir si vous êtes un robot.</p>
                <ReCAPTCHA 
                    sitekey='6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
                    ref = {props.recaptchaRef}
                    if = {v4()}
                    onChange={props.handleChange}
                />

                <motion.button 
                    whileHover={{scale : 1.05}}
                    whileTap={{scale : 0.95}}
                    onClick = {props.handleSubmit}
                >
                    Valider
                </motion.button>
            </motion.div>
        </motion.div>
    )
}

//REAL
//SW-6LfrXR4jAAAAAF3MHEqZNIYOrg1uxN9udPsAWMWb
//SECR-6LfrXR4jAAAAAEhZTboyxZITP7h_59AoapoacF1k

//FAKE
//SK- 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
//SECR - 6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe