import React, {useState, useRef} from 'react'
import {AnimatePresence, motion} from 'framer-motion'
import { container_register } from '../../../animations/homepage'
import { PopupRecaptcha } from '../../../tools/Popup'
import axios from 'axios'
import { route } from '../../../config'


export default function Login(props) {
    const [user, setUser] = useState({
        username : "", 
        password : ""
    })
    const [openCaptcha, setOpenCaptcha] = useState(false)
    const recaptchaRef = useRef()

    const handleChangeInput = (e) => {
        setUser(state => {
            return {
                ...state, 
                [e.target.name] : e.target.value
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        //console.log(user)
        props.setLoader(true)


        axios.post(route('login'), user)
        .then(res => {
            //console.log(res.data)
            setOpenCaptcha(false)
            localStorage.setItem('token', res.data.token)
            props.setConfig(state => {
                return {
                    ...state, 
                    token : res.data.token, 
                    account : res.data.account
                }
            })
            props.setLoader(false)
            return props.setStatusRequest(res.data)

        })
        .catch(err => { 
            props.setLoader(false)
            setOpenCaptcha(false)
            if(!err.response) {
                return props.setStatusRequest({
                    status : 0, 
                    message : "L'accès au serveur ne peut pas se faire. Veuillez contacter l'administrateur."
                })
            }
            return props.setStatusRequest(err.response.data)
        })


    }


    const handleValid = () => {
        if(user.username < 4) {
            return props.setStatusRequest({
                status : 2, 
                message : "L'identifiant doit contenir au moins 4 caractères."
            })
        }
        if(user.password < 4) {
            return props.setStatusRequest({
                status : 2, 
                message : "Le mot de passe doit contenir au moins 4 caractères."
            })
        }
        return setOpenCaptcha(true)
    }

    return (
        <React.Fragment>

            <AnimatePresence>
                {
                    openCaptcha ? 
                        <PopupRecaptcha
                            recaptchaRef = {recaptchaRef}
                            handleChange = {(value) => setUser(state => {
                                return {
                                    ...state, 
                                    recaptcha : value
                                }
                            })}
                            handleSubmit = {handleSubmit}
                            handleClose = {() => {
                                setOpenCaptcha(false)
                            }}
                        />
                    : 
                        null
                }
            </AnimatePresence>

            <motion.div 
                exit = "exit"
                animate = "animate" 
                initial = "initial"
                variants = {container_register}
                className="login">
                <h1>Connexion</h1>
                
                <form>
                    <div>
                        <label htmlFor={'username'}>Identifiant</label>
                        <input type = "text" name = "username" value = {user.username} onChange = {handleChangeInput}/>
                    </div>
                    <div>
                        <label htmlFor={'password'}>Mot de passe</label>
                        <input 
                            type = "password" name = "password" 
                            value = {user.password} 
                            onChange = {handleChangeInput}
                            onKeyDown = {(e) => e.key === "Enter" ? handleValid() : null }
                        />
                    </div>
                </form>

                <div className='register__buttons'>
                    <motion.button 
                        whileHover={{scale : 1.05}}
                        whileTap={{scale : 0.95}}
                        onClick = {() => props.setMode('homepage')}
                    >
                        Retour
                    </motion.button>
                    <motion.button 
                        whileHover={{scale : 1.05}}
                        whileTap={{scale : 0.95}}
                        onClick = {handleValid}
                    >
                        Valider
                    </motion.button>
                </div>
            </motion.div>
        </React.Fragment>
    )
}