import adrien from '../images/971fc707-9a85-4bdf-b6c2-baef8ff2b001.jpg'
import elodie from '../images/a132ec0e-7667-4a2a-8141-5efb96a219b2.jpg'
import mylena from '../images/e82679a1-85f6-4ca6-933a-757ca257af3c.jpg'
import philippe from '../images/4096b5cf-35bd-4e15-986a-42721fc9e3cb.jpg'
import tys from '../images/3780c9ad-b0bb-49b6-9c7a-e8444e2b7846.jpg'

export const staffSupp = [
    {
        idUUID : "971fc707-9a85-4bdf-b6c2-baef8ff2b001", 
        name : "Adrien Debese", 
        image : adrien, 
        idUser : "c57f3de3-d6be-4765-9fe5-b9e16d3b2903"
    }, 
    {
        idUUID : "a132ec0e-7667-4a2a-8141-5efb96a219b2", 
        name : "Elodie Payet", 
        image : elodie, 
        idUser : "57829468-a6b4-412e-97fe-94cc2935e7af"
    }, 
    {
        idUUID : "e82679a1-85f6-4ca6-933a-757ca257af3c", 
        name :  "Myléna Fribourg", 
        image : mylena, 
        idUser : "71f2d2f7-3074-4455-b255-7892690d422c"
    }, 
    {
        idUUID : "4096b5cf-35bd-4e15-986a-42721fc9e3cb", 
        name : "Philippe Bertet", 
        image : philippe
    }, 
    {
        idUUID : "3780c9ad-b0bb-49b6-9c7a-e8444e2b7846", 
        name : "Tys Bulin", 
        image : tys, 
        idUser : "996552b9-317b-4169-ae17-f994e253877c"
    }, 
    {
        idUUID : "407e215a-4c69-47a8-85df-5174f88fb492", 
        name : "Romain Gauchet", 
    }, 
    {
        idUUID : "43c39c01-513a-4f9c-859e-1792261b9703", 
        name : "Justine Blaguenville", 
    },
    {
        idUUID : "2eef8ff1-0a9d-4ca6-bc13-0b012867a2ee", 
        name : "Linda Defaud", 
    }, 
    {
        idUUID : "2ddddf8ff1-0a9d-4ca6-bc13-0b012867a2ee", 
        name : "Ruanito Teaurobolion", 
    }, 
    {
        idUUID : "2ddddfkjnp999d-4ca6-bc13-0b012867a2ee", 
        name : "Thomas Dijoux", 
    }, 
    {
        idUUID : "34eerrfr-4ca6-bc13-0b012867a2ee", 
        name : "Katy Hoarau", 
    }, 
    {
        idUUID : "3455EEP-ejio0b012867a2ee", 
        name : "Philippe Hoarau", 
    }, 
    {
        idUUID : "3455EEPzleppooi867a2ee", 
        name : "Jonathan Taochy", 
    },
    {
        idUUID : "194eeEEPpkaokpokep099", 
        name : "Egyptienne Lin", 
    },  
    {
        idUUID : "13847uejkalosss900-849322", 
        name : "Tatiana Payet", 
    }, 
    {
        idUUID : "130948ueuhjkfll-eooprjhbcjj", 
        name : "Karine Hang Si Lan", 
    },


]


//'[{"idUUID":"e82679a1-85f6-4ca6-933a-757ca257af3c","response":""},{"idUUID":"971fc707-9a85-4bdf-b6c2-baef8ff2b001","response":""},{"idUUID":"4096b5cf-35bd-4e15-986a-42721fc9e3cb","response":""},{"idUUID":"a132ec0e-7667-4a2a-8141-5efb96a219b2","response":""},{"idUUID":" 3780c9ad-b0bb-49b6-9c7a-e8444e2b7846","response":""}]'