import React, {useState, useRef} from 'react'
import {v4} from 'uuid'
import axios from 'axios'
import {motion, AnimatePresence} from 'framer-motion'
import {ReactComponent as UserPhoto} from '../../../assets/user_photo.svg'
import {ReactComponent as Upload} from '../../../assets/upload.svg'
import { container_register, step_register } from '../../../animations/homepage'
import { route } from '../../../config'
import { PopupRecaptcha } from '../../../tools/Popup'

const register_form_structure = [
    {
        id : 'RFS-01', 
        name : "lastname", 
        text : "Nom", 
        limit : "20"
    }, 
    {
        id : 'RFS-02', 
        name : "firstname", 
        text : "Prénom", 
        limit : "20"
    }, 
    {
        id : 'RFS-01', 
        name : "username", 
        text : "Pseudo", 
        limit : "20"
    }, 
    {
        id : 'RFS-01', 
        name : "password", 
        text : "Mot de passe", 
    }, 
]

export default function Register(props) {
    const [step, setStep] = useState(0)
    const [openCaptcha, setOpenCaptcha] = useState(false)
    const [newUser, setNewUser] = useState({
        idUUID : v4(), 
        lastname : "", 
        firstname : "", 
        username : "", 
        password : "", 
        recentpic : "", 
        childpic : "", 
        results : '[]'
    })
    const recaptchaRef = useRef()

    const handleChangeStep = () => {
        if(step < 2) {
            setStep(step + 1)
        } else {
            setStep(step - 1)
        }
    }

    const handleSubmit = (e) => {
        props.setLoader(true)
        e.preventDefault()
        //console.log(props.newUser)
        const formContainer = new FormData()
        for(var element of Object.entries(newUser)) {
            formContainer.append(element[0], element[1])
        }

        axios.post(route('register'), formContainer)

        .then(res => {
            setOpenCaptcha(false)
            props.setMode("homepage") 
            props.setLoader(false)
            return props.setStatusRequest(res.data)

        })
        .catch(err => { 
            props.setLoader(false)
            if(!err.response) {
                return props.setStatusRequest({
                    status : 0, 
                    message : "L'accès au serveur ne peut pas se faire. Veuillez contacter l'administrateur."
                })
            }
            return props.setStatusRequest(err.response.data)
        })
    }


    return (
        <motion.div 
        exit = "exit"
        animate = "animate" 
        initial = "initial"
        variants = {container_register}
        className="register"
    >
        <AnimatePresence>
        {
            openCaptcha ? 
                <PopupRecaptcha 
                    recaptchaRef = {recaptchaRef}
                    handleChange = {(value) => setNewUser(state => {
                        return {
                            ...state, 
                            recaptcha : value
                        }
                    })}
                    handleSubmit = {handleSubmit}
                    handleClose = {() => {
                        setOpenCaptcha(false)
                    }}
                />
            : 
                null
        }
        </AnimatePresence>

        <AnimatePresence mode = "wait" initial = {false}>
        {
            step === 0 ? 
                <FirstStep 
                    setMode = {props.setMode}
                    handleChangeStep = {async () => {
                        if(newUser.lastname.length < 1) {
                            return props.setStatusRequest({
                                status : 2, 
                                message : "Votre nom de famille doit avoir au moins un caractère."
                            })
                        }
                        if(newUser.lastname.length > 20) {
                            return props.setStatusRequest({
                                status : 2, 
                                message : "Votre nom de famille ne peut avoir qu'un macimum de 20 caractères."
                            })
                        }

                        if(newUser.firstname.length < 1) {
                            return props.setStatusRequest({
                                status : 2, 
                                message : "Votre prénom doit avoir au moins un caractère."
                            })
                        }
                        if(newUser.firstname.length > 20) {
                            return props.setStatusRequest({
                                status : 2, 
                                message : "Votre prénom ne peut avoir qu'un macimum de 20 caractères."
                            })
                        }

                        if(newUser.username.length <= 3) {
                            return props.setStatusRequest({
                                status : 2, 
                                message : "Votre pseudo doit avoir au moins 4 caractères."
                            })
                        }
                        if(newUser.username.length > 20) {
                            return props.setStatusRequest({
                                status : 2, 
                                message : "Votre pseudo ne peut dépasser 20 caractères."
                            })
                        }
                        

                        if(newUser.password.length <= 7) {
                            return props.setStatusRequest({
                                status : 2, 
                                message : "Votre mot de passe doit avoir au moins 8 caractères."
                            })
                        }

                        props.setLoader(true)

                        return axios.get(route('public'))
                        .then(res => {
                            const tranformedData = []
                            res.data.map(element => {
                                tranformedData.push(element.username.trim().toLowerCase())
                            })

                            props.setLoader(false)

                            if(tranformedData.includes(newUser.username.trim().toLowerCase())) {
                                return props.setStatusRequest({
                                    status : 2, 
                                    message : "Ce pseudo est déjà utilisé."
                                })
                            }
                            
                            return handleChangeStep()
                        })
                        .catch(err => {
                            props.setLoader(false)
                            if(!err.response) {
                                return props.setStatusRequest({
                                    status : 0, 
                                    message : "L'accès au serveur ne peut pas se faire. Veuillez contacter l'administrateur."
                                })
                            }
                            return props.setStatusRequest(err.response.data)

                        })


                    }
                    }
                    newUser = {newUser}
                    setNewUser = {setNewUser}

                />
            : 
                step === 1 ? 
                    <SecondStep 
                        newUser = {newUser}
                        setNewUser = {setNewUser}
                        handleChangeStep = {handleChangeStep}
                        setStep = {setStep}
                    />
                : 
                    <ThirdStep 
                        handleChangeStep = {handleChangeStep}
                        setStep = {setStep}
                        newUser = {newUser}
                        setNewUser = {setNewUser}
                        handleSubmit = {() => setOpenCaptcha(!openCaptcha)}
                    />
        }
        </AnimatePresence>

    </motion.div>
    )
}

function FirstStep(props) {


    const handleChangeInput = (e) => {
        props.setNewUser(state => {
            return {
                ...state, 
                [e.target.name] : e.target.value.replace(' ', '')
            }
        })
    }

    return (
        <motion.div 
            exit = "exit"
            animate = "animate" 
            initial = "initial"
            variants = {step_register}
            className='step'
        >
            <motion.h1>
                Inscription
            </motion.h1>

            <p>Les informations que vous allez saisir sont nécessaires à votre identification et à votre participation.</p>

            <form>
                {
                    register_form_structure.map(element => (
                        <div key = {JSON.stringify(element)}>
                            <label htmlFor={element.name}>{element.text}</label>
                            <input 
                                type = {element.name === "password" ? "password" : "text" }
                                name = {element.name} 
                                value = {props.newUser[element.name]}
                                onChange = {handleChangeInput}
                                maxLength = {element.limit}

                            />
                        </div>
                    ))
                }
            </form>

            <div className='register__buttons'>
                <motion.button 
                    whileHover={{scale : 1.05}}
                    whileTap={{scale : 0.95}}
                    onClick = {() => props.setMode('homepage')}
                >
                    Annuler
                </motion.button>
                <motion.button 
                    whileHover={{scale : 1.05}}
                    whileTap={{scale : 0.95}}
                    onClick = {() => props.handleChangeStep()}
                >Étape suivante</motion.button>
            </div>
        </motion.div>
    )
}

function SecondStep(props) {
    const file = useRef(null)
    const [filePreview, setFilePreview] = useState('')

    const handleClickFile = (e) => {
        e.preventDefault()
        file.current.click()
    }

    const handleChangeFile = (e) => {
        const fileUploaded = e.target.files[0]
        if (fileUploaded) {
            props.setNewUser(state => {
                return {
                    ...state, 
                    [e.target.name] : fileUploaded
                }
            })
            setFilePreview(URL.createObjectURL(fileUploaded))
        } 
    }


    return (

        <motion.div 
            exit = "exit"
            animate = "animate" 
            initial = "initial"
            variants = {step_register}
            className='step'
        >
            <h1>Une photo d'enfance</h1>
            <p>Afin de participer à l’élaboration du calendrier de l’avent, merci de bien vouloir ajouter une photo de vous enfant.</p>
            <div className='register__childPic'>
                {
                    filePreview.length > 0 ? 
                        <div className='filePreview'>
                            <img src = {filePreview} alt = "FilePreview" />
                        </div>
                    : 
                        <div><UserPhoto /></div>
                }

                <motion.button
                        whileTap={{scale : 0.9}}
                        whileHover={{scale : 1.05}}
                     onClick = {handleClickFile}>
                    <Upload />
                    <p>Télécharger</p>
                </motion.button>
                <input type = "file" accept="image/png, image/jpeg, image/jpg" style = {{display : 'none'}} name = "childpic" onChange = {handleChangeFile} ref = {file}/>

            </div>

            <div className='register__buttons'>
                <motion.button 
                    whileHover={{scale : 1.05}}
                    whileTap={{scale : 0.95}}
                    onClick = {() => props.setStep(state => state - 1)}
                >
                    Retour
                </motion.button>
                <motion.button 
                    whileHover={{scale : 1.05}}
                    whileTap={{scale : 0.95}}
                    onClick = {props.handleChangeStep}
                >
                    Étape suivante
                </motion.button>
            </div>
        </motion.div>
    )
}

function ThirdStep(props) {
    const file = useRef(null)
    const [filePreview, setFilePreview] = useState('')

    const handleClickFile = (e) => {
        e.preventDefault()
        file.current.click()
    }

    const handleChangeFile = (e) => {
        const fileUploaded = e.target.files[0]
        if (fileUploaded) {
            props.setNewUser(state => {
                return {
                    ...state, 
                    [e.target.name] : fileUploaded
                }
            })
            setFilePreview(URL.createObjectURL(fileUploaded))
        } 
    }


    return (

        <motion.div 
            exit = "exit"
            animate = "animate" 
            initial = "initial"
            variants = {step_register}
            className='step'
        >
            <h1>Une photo récente</h1>
            <p>Afin que tous les participants puissent vous identifier et procéder correctement au vote, merci de bien vouloir ajouter une photo de vous récente. </p>
            <div className='register__childPic'>
                {
                    filePreview.length > 0 ? 
                        <div className='filePreview'>
                            <img src = {filePreview} alt = "FilePreview" />
                        </div>
                    : 
                        <div><UserPhoto /></div>
                }
                <motion.button 
                    whileTap={{scale : 0.9}}
                    whileHover={{scale : 1.05}}
                    onClick = {handleClickFile}>
                    <Upload />
                    <p>Télécharger</p>
                </motion.button>
                <input type = "file" accept="image/png, image/jpeg, image/jpg" style = {{display : 'none'}} name = "recentpic" onChange = {handleChangeFile} ref = {file}/>
            </div>

            <div className='register__buttons'>
                <motion.button 
                    whileHover={{scale : 1.05}}
                    whileTap={{scale : 0.95}}
                    onClick = {() => props.setStep(state => state - 1)}
                >
                    Retour
                </motion.button>
                <motion.button 
                    whileHover={{scale : 1.05}}
                    whileTap={{scale : 0.95}}
                    onClick = {props.handleSubmit}
                >
                        Finaliser l'inscription
                </motion.button>
            </div>
        </motion.div>
    )
}

