
/*

exit = "exit"
animate = "animate" 
initial = "initial"
variants = {}

export const variable = {
    exit : {

    }, 
    animate : {

    }, 
    initial : {
        
    }
}

*/

export const title_twentyFour = {
    exit : {
        y : -50, 
        opacity : 0
    }, 
    animate : {
        y : 0, 
        opacity : 1, 
        transition : {
            duration : 0.3, 
            //delay : 0.6
        }
    }, 
    initial : {
        y : -50, 
        opacity : 0
    }
}

export const text_twentyFour = {
    exit : {
        y : -50, 
        opacity : 0
    }, 
    animate : {
        y : 0, 
        opacity : 1, 
        transition : {
            duration : 0.4, 
            delay : 0.3
        }
    }, 
    initial : {
        y : -50, 
        opacity : 0
    }
}

export const response_twentyFour = {
    exit : {
        scale : 0
    }, 
    animate : {
        scale : 1,
        transition : {
            duration : 0.4, 
            delay : 0.3
        }
    }, 
    initial : {
        scale : 0
    }
}