import React, {useEffect} from 'react'
import { motion } from "framer-motion";
import { AnimatePresence } from 'framer-motion';
import {ReactComponent as Cancel} from '../assets/gift_cancel.svg'
import {ReactComponent as Warning} from '../assets/gift_warning.svg'
import {ReactComponent as Check} from '../assets/gift_check.svg'

export default function StatusRequest({statusRequest, setStatusRequest}) {
    useEffect(() => {
        const timer = setTimeout(() => {
            setStatusRequest({})
        }, 2000);
        return () => clearTimeout(timer);
      });
    
    const classNameStatus = statusRequest.status === 0 ? "statusRequest__cancel" : statusRequest.status === 1 ? "statusRequest__check" : "statusRequest__warning"
    return ( 
        <AnimatePresence>
            {Object.entries(statusRequest).length !== 0 ? 
                <motion.div 
                exit = {{y : -500, x : '-50%'}}
                animate = {{y : 0, x : '-50%'}}
                initial = {{y : -500, x : '-50%'}}
                transition = {{
                    type : 'linear', 
                }}
                className = {`statusRequest ${classNameStatus}`}>
                        <div>
                            {
                                statusRequest.status === 0 ?            
                                    <Cancel />
                                : 
                                    statusRequest.status === 1 ? 
                                        <Check />
                                            :   
                                                <Warning />
                            }
                        </div>
                        <p>{statusRequest.message}</p>

                </motion.div>
            :
            null
            }
        </AnimatePresence>
    )
}
