import React, {useState} from 'react'
import {motion, AnimatePresence} from 'framer-motion'
import {ReactComponent as Off} from '../../assets/disconnect.svg'
import {ReactComponent as ActivityIcon} from '../../assets/activity.svg'
import {ReactComponent as TheEnd} from '../../assets/theEnd.svg'
import Scores from './layouts/Scores'
import Calendar from './layouts/Calendar'
import { box_session, buttons_session, letter_session, popup_buttons_session, title_box_session, title_session, tree_session } from '../../animations/session'
import Timer from '../../tools/Timer'
import Gameplay from '../../tools/Gameplay'
import axios from 'axios'
import { route } from '../../config'
import Activity from './layouts/Activity'
import TwentyFour from '../twentyfour/TwentyFour'
import surprise from '../../assets/Surprise.pdf'


export default function Session(props) {
    //State Managers for Popups
    const [popups, setPopups] = useState('')
    const [game, setGame] = useState({})
    const [activity, setActivity] = useState(false)
    

    const handleChangePopups = (popup) => {
        setPopups(popup)
    }


    return (
        <React.Fragment>
            {/* ACTIVITY */}



            {/* ACTIVITY */}
            <AnimatePresence>
                {
                    activity ? 
                        <Activity
                            calendar = {props.config.dataGame}
                            currentDate = {props.config.currentDate.day}
                            activity = {activity}
                            setActivity = {setActivity}
                            
                        />

                    : 
                        null
                }

            </AnimatePresence>


            {/* GAMEPLAY */}
            <AnimatePresence>
            {
            //GAMEPLAY
                Object.entries(game).length > 0 ? 
                    props.config.currentDate.day === '24' ? 
                    <TwentyFour 
                        config = {props.config}
                        setStatusRequest = {props.setStatusRequest}
                        setConfig = {props.setConfig}
                        setLoader = {props.setLoader}
                        handleClose = {() => setGame({})}
                        setViewer = {props.setViewer}
                    />

                    :

                    <Gameplay
                        currentDate = {props.config.currentDate.day}
                        day = {game.day}
                        data = {props.config.dataGame}
                        handleClose = {() => setGame({})}
                        accountResult = {props.config.account.results}
                        config = {props.config}
                        setConfig = {props.setConfig}
                        setStatusRequest = {props.setStatusRequest}
                        setLoader = {props.setLoader}
                        setViewer = {props.setViewer}
                    />
                : 
                    null

            }
            </AnimatePresence>


            {/* SCORES */}       
            <AnimatePresence>
            {
                popups === "scores" ? 
                    <Scores 
                        data = {props.config.dataGame}
                        account = {props.config.account}
                        handleClose = {() => handleChangePopups('')}
                        day = {props.config.currentDate.day}
                    />
                : 
                    null
            }
            </AnimatePresence>

            {/* CALENDAR */}
            <AnimatePresence>
            {
                popups === "calendar" ? 
                    <Calendar
                        day = {props.config.currentDate.day}
                        handleOpenGame = {() => setGame({ day : props.config.currentDate.day})}
                        handleClose = {() => handleChangePopups('')}
                        handleSetGame= {(number) => setGame({day : `${number}`}) }
                    />
                : 
                    null
            }
            </AnimatePresence>

            {/* HOMEPAGE SESSION */}
            <div className={props.config.currentDate.month === '12' ? props.config.currentDate.day < 25 ? "session" : "session session__theEnd" : "session timer__background"} >
                <div className = "session__user">
                    <motion.button
                        exit = "exit"
                        animate = "animate" 
                        initial = "initial"
                        variants = {buttons_session}
                        whileHover = {{scale : 1.05}}
                        whileTap= {{scale : 0.9}}
                        onClick = {props.handleDisconnect}
                    >
                        <Off />
                    </motion.button>



                    {
                        /*

                        ! ACTIVITY APRES 24
                    <motion.button
                        exit = "exit"
                        animate = "animate" 
                        initial = "initial"
                        variants = {buttons_session}
                        whileHover = {{scale : 1.05}}
                        whileTap= {{scale : 0.9}}
                        onClick={() => setActivity(!activity)}
                    >
                        <ActivityIcon />

                    </motion.button>

                        <motion.button
                            exit = "exit"
                            animate = "animate" 
                            initial = "initial"
                            variants = {buttons_session}
                            whileHover = {{scale : 1.05}}
                            whileTap= {{scale : 0.9}}
                            
                        >
                            <UserSettings />
                        </motion.button>
                        
                        */
                    }

                </div>

                <motion.h1
                    exit = "exit"
                    animate = "animate" 
                    initial = "initial"
                    variants = {title_session}
                >
                    Le Calendrier de l'Avent
                </motion.h1>

                {
                parseInt(props.config.currentDate.month) === 12 ? 
                    parseInt(props.config.currentDate.day) <= 24 ? 
                    <div className = "today">
                    <div 
                        className='today__box'
                    >
                        <motion.p
                            exit = "exit"
                            animate = "animate" 
                            initial = "initial"
                            variants = {title_box_session}
                        >Jour {props.config.currentDate.day}</motion.p>
                        <motion.div
                            exit = "exit"
                            animate = "animate" 
                            initial = "initial"
                            variants = {box_session}
                            whileHover = {{scale : 1.05}}
                            whileTap = {{scale : 0.9}}
                            onClick = {() => setGame({ day : props.config.currentDate.day})}
                        >?</motion.div>
                    </div>
                    <motion.div 
                        className='today__tree'
                        exit = "exit"
                        animate = "animate" 
                        initial = "initial"
                        variants = {tree_session}
                    
                    ></motion.div>
                </div>

                    : 
                        <motion.div 
                            className='afterChristmas'
                            exit = "exit"
                            animate = "animate" 
                            initial = "initial"
                            variants = {letter_session}
                        >
                            <TheEnd />
                        </motion.div>

                :
                <React.Fragment>
                    <p className='timer__text'>Il ne reste plus beaucoup de temps avant le début du jeu. Encore un peu de patience !</p>
                    <Timer />
                </React.Fragment>

            }   

            {
                props.config.currentDate.month === '12' ? 
                    <motion.div 
                        exit = "exit"
                        animate = "animate" 
                        initial = "initial"
                        variants = {popup_buttons_session}
                        className='session__buttons'
                        >

                        {
                            /*props.config.currentDate.day > 24 ? 
                                <motion.a
                                whileTap={{scale : 0.9}}
                                whileHover={{scale : 1.05}}
                                target = "_blank"
                                href = {surprise}
                                >
                                    Télécharger la surprise
                                </motion.a>

                            : 
                                null*/
                        }
                        <motion.button 
                            whileTap={{scale : 0.9}}
                            whileHover={{scale : 1.05}}
                            onClick = {() => setPopups('scores')}>
                            Tableau des scores
                        </motion.button>
                        {
                            props.config.currentDate.day < 25 ? 
                                <motion.button 
                                whileTap={{scale : 0.9}}
                                whileHover={{scale : 1.05}}
                                onClick = {() => setPopups('calendar')}
                                >
                                    Calendrier
                                </motion.button>

                            : 
                                null
                        }

                    </motion.div>
                : 
                    null

            }

            </div>

        </React.Fragment>
    )
}

