import React from 'react'
import Popup from "../../../tools/Popup";
import { motion } from 'framer-motion';
import {ReactComponent as Branche4} from '../../../assets/branche_4.svg'
import { box_calendar, branche_calendar,  title_calendar} from '../../../animations/session';


export default function Calendar(props) {

    return (
        <Popup
            handleClose = {props.handleClose}
            nameClass = {'calendar--popup'}
        >
            <div className='calendar'>
            <motion.h1
                    exit = "exit"
                    animate = "animate" 
                    initial = "initial"
                    variants = {title_calendar}
            >
                
                Le calendrier
            </motion.h1>
            <motion.div 
                exit = "exit"
                initial = "initial"
                animate = "animate"
                variants={branche_calendar}
                className='branche'>
                <Branche4 />
            </motion.div>

            <div className='content'>
                {
                    Array.from(Array(25).keys()).filter(element => element !== 0).map(element => (
                        <motion.div 
                            exit = "exit"
                            initial = "initial"
                            animate = "animate"
                            variants={box_calendar}
                            transition={{
                                type : "linear", 
                                delay : 0.1 * ((element / 1.75) + 0.01)
                            }}

                            onClick={
                                //SI LA BOITE EST EGAL AU JOUR DEMANDE ALORS FONCTION 
                                element < parseInt(props.day) ? () => props.handleSetGame(element) : element === parseInt(props.day) ? props.handleOpenGame : null
                            }
                                
                            className={element < parseInt(props.day) ? 'calendar__passed' : element === parseInt(props.day) ? "calendar__today" : "calendar__coming"}
                            key = {`BOX-0${element}`}

                        >
                            {element}
                        </motion.div>
                    ))
                }
            </div>

            </div>
        </Popup>
    )
}