/*

exit = "exit"
animate = "animate" 
initial = "initial"
variants = {}

export const variable = {
    exit : {

    }, 
    animate : {

    }, 
    initial : {
        
    }
}

*/

export const background_popup = {
    exit : {
        opacity : 0
    }, 
    animate : {
        opacity : 1, 
        transition : {
            duration : 0.4
        }
    }, 
    initial : {
            opacity : 0
    }
}

export const buttons_popup = {
    exit : {
        scale : 0, 
        opacity : 0
    }, 
    animate : {
        scale : 1, 
        opacity : 1, 
        transition : {
            duration : 0.3, 
            delay : 0.4
        }
    }, 
    initial : {
        scale : 0, 
        opacity : 0
    }
}

export const recaptcha__popup = {
    exit : {
        scale : 0, 
        opacity : 0
    }, 
    animate : {
        scale : 1, 
        opacity : 1,
        transition : {
            type : 'linear', 
            delay : 0.4
        }
    }, 
    initial : {
        scale : 0,
        opacity : 0
    }
}



//GAMEPLAY

export const background__gameplay= {
    exit : {
        opacity : 0
    }, 
    animate : {
        opacity : 1
    }, 
    initial : {
        opacity : 0
    }
}

export const crown__gameplay= {
    exit : {
        scale : 0,  
        opacity : 0, 
        rotate : 0, 
        y : '-50%',
        x : '-50%'
    }, 
    animate : {
        scale : 1,  
        opacity : 1, 
        rotate : 360, 
        y : '-50%',
        x : '-50%', 
        transition : {
            delay : 0.3, 
            duration : 0.6
        }
    }, 
    initial : {
        scale : 0,  
        opacity : 0, 
        rotate : 0, 
        y : '-50%',
        x : '-50%'
    }
}



export const childpic__gameplay= {
    exit : {
        scale : 0,  
        opacity : 0, 
        rotate : 0, 

    }, 
    animate : {
        scale : 1,  
        opacity : 1, 
        rotate : -360, 

        transition : {
            delay : 0.6, 
            duration : 0.6
        }
    }, 
    initial : {
        scale : 0,  
        opacity : 0, 
        rotate : 0, 

    }
}


export const choose_gameplay = {
    exit : {
        y: 100, 
        opacity : 0
    }, 
    animate : {
        y: 0, 
        opacity : 1, 
        transition : {
            duration : 0.4, 
            delay : 0.8
        }
    }, 
    initial : {
        y: 100, 
            opacity : 0
    }
}

export const slide_gameplay = {
    exit : {
        //x: -500, 
        scale : 0, 
        opacity : 0
    }, 
    animate : {
        //x: 0, 
        scale : 1, 
        opacity : 1, 
        transition : {
            duration : 0.2, 
        }
    }, 
    initial : {
        //x: 500, 
        scale : 0, 
            opacity : 0
    }
}


export const submit_gameplay = {
    exit : {
        scale : 0, 
        opacity : 0

    }, 
    animate : {
        scale : 1, 
        opacity : 1
    }, 
    initial : {
        scale : 0, 
        opacity : 0
    }
}


//Loader
export const container_loader = {
    exit : {
        opacity : 0
    }, 
    animate : {
        opacity : 1
    }, 
    initial : {
        opacity : 0
    }
}

export const svg_loader = {
    exit : {
        opacity : 0, 
        scale : 0.5
    }, 
    animate : {
        opacity : 1 ,
        scale : 1, 
        transition : {
            delay : 0.5
        }
    }, 
    initial : {
        opacity : 0, 
        scale : 0.5
    }
}

///VIEWER

export const image_viewer = {
    exit : {
        rotate : 0, 
        scale : 0
    }, 
    animate : {
        rotate : 360, 
        scale : 1, 
        transition : {
            delay : 0.6, 
            type : 'linear'
        }
    }, 
    initial : {
        rotate : 0, 
        scale : 0
    }
}