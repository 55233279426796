import React, {useState, useEffect} from 'react'
import axios from 'axios';
import Popup from "../../../tools/Popup";
import {motion} from 'framer-motion'
import {ReactComponent as Branche3} from '../../../assets/branche_3.svg'
import { Scrollbars } from 'react-custom-scrollbars-2';
import {ReactComponent as UserDefault} from '../../../assets/user_default.svg' ; 
import {ReactComponent as SpinningCircles} from '../../../assets/spinning-circles.svg' ; 
import { branche_scores, table_scores, title_scores } from "../../../animations/session";
import { route } from '../../../config';
import { staffSupp } from '../../twentyfour/assets/data';

export default function Scores(props) {
    const [tabScores, setTabScores] = useState([])
        /*
            J'ai besoin de : 
                - IMG RECENT DES UTILISATEURS
        
        

        */
        const getResultsInt = (results) => {
            /*props.data.filter(subElement => parseInt(subElement.day) < parseInt(props.day)).map(element => {
                    if(results.find(element.idUUID)) {
                    if(element.idgamer === results.find(element.idUUID).response) {
                        return 1
                    } else {
                        return 0
                    }
                } else {
                    return 0
                }
            })*/

            return props.data.filter(element => parseInt(element.day) < parseInt(props.day)).map(element => {
                if(results.length > 0) {
                    if(results.find(subElement => subElement.idUUID === element.idUUID)) {
                        if(element.idgamer === results.find(subElement => subElement.idUUID === element.idUUID).response) {
                    
                            return 1
                        } else {
                            return 0
                        }
                    } else {
                        return 0
                    }
                } else {
                    return 0
                }
            })
        }

        const getTwentyFourInt = (scores) => {
            const picsFor24 = staffSupp.filter(element => element.image)
            
            return scores.map(element => {

                if(element.response === picsFor24.find(subEl => subEl.idUUID === element.idUUID).name) {
                    return 1
                } else {
                    return 0
                }
            
            })

        }



        useEffect(() => {
            if(tabScores.length === 0 ) {
                axios.get(route('game/users'))
                .then(res => {
                    const transformedData = res.data.map(element => {

                        return {
                            ...element, 
                            results : props.day > "24" ? [...getResultsInt(JSON.parse(element.results)), ...getTwentyFourInt(JSON.parse(element.twentyFour))] : getResultsInt(JSON.parse(element.results)), 
                        }
                    })
                    setTabScores(transformedData)
                    
    
                })
                .catch(err => {
                    console.log(err)
                })
            }

        }, [tabScores])
        //console.log(tabScores[21].twentyFour)

    return (
        <Popup
            handleClose = {props.handleClose}
        >
            <div className="scores">
                <motion.h1
                    exit = "exit"
                    animate = "animate" 
                    initial = "initial"
                    variants = {title_scores}
                >Tableau des scores</motion.h1>
                <motion.div
                    exit = "exit"
                    animate = "animate" 
                    initial = "initial"
                    variants = {table_scores}
                >
                    <motion.div 
                        exit = "exit"
                        animate = "animate" 
                        initial = "initial"
                        variants = {branche_scores}
                        className="branche"
                    >
                        <Branche3 />
                    </motion.div>
                    <div className="table">
                        <div className="thead">
                            <div>Participants</div>
                            <div>Points</div>
                        </div>    
                        <div className="tbody">
                            <p className="tbody__gradient"></p>
                            <Scrollbars className='scrollContent'>
                                {
                                    tabScores.length > 0 ? 
                                        tabScores.sort((a, b) => parseInt(b.results.reduce((a, b) => a + b, 0)) - parseInt(a.results.reduce((a, b) => a + b, 0))).map((element, position) => (
                                            <div className="tbody__element" key = {JSON.stringify(element)}>
                                                <div className={element.idUUID === props.account.idUUID ? "you" : ""}>
                                                    <span>{position + 1}</span>
                                                    <div>
                                                        {
                                                            element.recentpic.length > 0 ? 
                                                             <img src = {route(element.recentpic)} alt = {element.firstname + element.lastname} />
                                                            : 
                                                                <UserDefault />
                                                            
                                                        }

                                                    
                                                    </div>
                                                    {
                                                        element.idUUID === props.account.idUUID ? 
                                                            <p><span>Moi</span></p>
                                                        : 
                                                            <p>
                                                            <span>{element.firstname.toLowerCase()} </span> 
                                                            <span>{element.lastname.toLowerCase()}</span> 
                                                            
                                                            </p>

                                                    }

                                                </div>
                                                <div className={element.idUUID === props.account.idUUID ? "you" : ""}>{element.results.reduce((a, b) => a + b, 0)}</div>
                                            </div>
                                        ))
                                    : 
                                        <div className='noData'><SpinningCircles /></div>
                                }

                            </Scrollbars>

                        </div>
                    </div>

                </motion.div>
            </div>

        </Popup>
    )
}


