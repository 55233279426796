import React, {useEffect} from 'react'
import { motion } from 'framer-motion'
import { container_loader, svg_loader } from '../animations/tools'



export default function Loader() {
    return (
        <div className="loader">
            <motion.div 
                className="tree"
                exit = "exit"
                animate = "animate" 
                initial = "initial"
                variants = {container_loader}
            >
                <motion.svg 
                    viewBox="0 0 120 140"
                    exit = "exit"
                    animate = "animate" 
                    initial = "initial"
                    variants = {svg_loader}
                >
                    <polygon className="p" fill="none" strokeWidth="1" points="60,10 10,110 110,110 "/>
                    <circle className="c c1"cx="30" cy="100" r="4" fill="none" strokeWidth="1"/>
                    <circle className="c c2" cx="65" cy="40" r="4" fill="none" strokeWidth="1"/>
                    <circle className="c c3" cx="90" cy="90" r="4" fill="none" strokeWidth="1"/>
                    <circle className="c c4" cx="50" cy="60" r="4" fill="none" strokeWidth="1"/>
                    <circle className="c c5" cx="69" cy="102" r="4" fill="none" strokeWidth="1"/>
                    <circle className="c c6" cx="45" cy="80" r="4" fill="none" strokeWidth="1"/>
                    <circle className="c c7" cx="75" cy="70" r="4" fill="none" strokeWidth="1"/>
                </motion.svg>

            </motion.div>
        </div>
    )
}