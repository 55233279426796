/*

exit = "exit"
animate = "animate" 
initial = "initial"
variants = {}

export const variable = {
    exit : {

    }, 
    animate : {

    }, 
    initial : {
        
    }
}

*/

//SESSION

export const buttons_session = {
    exit : {
        scale : 0, 
        opacity : 0
    }, 
    animate : {
        scale : 1, 
        opacity : 1, 
        transition : {
            duration : 0.5, 
            delay : 0.6
        }
    }, 
    initial : {
        scale : 0, 
        opacity : 0
    }
}

export const title_session = {
    exit : {
        y : -50, 
        opacity : 0
    }, 
    animate : {
        y : 0, 
        opacity : 1, 
        transition : {
            duration : 0.8, 
            delay : 0.6
        }
    }, 
    initial : {
        y : -50, 
        opacity : 0
    }
}

export const box_session = {
    exit : {
        scale : 0, 
        rotate : '180deg'
    }, 
    animate : {
        scale : 1, 
        rotate : '360deg', 
        transition : {
            duration : 0.8, 
            delay : 0.6
        }
    }, 
    initial : {
        scale : 0, 
        rotate : '0deg'
    }
}

export const title_box_session = {
    exit : {
        opacity : 0, 
        y : 100
    }, 
    animate : {
        opacity : 1, 
        y : 0, 
        transition : {
            duration : 0.8, 
            delay : 0.8
        }
    }, 
    initial : {
        opacity : 0, 
        y : 100
    }
}

export const tree_session = {
    exit : {
        x : 1000
    }, 
    animate : {
        x : 0,
        transition : {
            duration : 0.8,
            delay : 0.6 

        }
    }, 
    initial : {
        x : 1000
    }
}

export const popup_buttons_session = {
    exit : {
        y : 500
    }, 
    animate : {
        y : 0, 
        transition : {
            duration : 0.8, 
            delay : 0.5
        }
    }, 
    initial : {
        y : 500
    }
}

//SCORES
export const title_scores = {
    exit : {
        y : -50, 
        opacity : 0
    }, 
    animate : {
        y : 0, 
        opacity : 1, 
        transition : {
            duration : 0.4, 
            delay : 0.4
        }
    }, 
    initial : {
        y : -50, 
        opacity : 0
    }
}


export const table_scores = {
    exit : {
        y : 1000, 
        opacity : 0
    }, 
    animate : {
        y : 0, 
        opacity : 1, 
        transition : {
            type : 'linear',
            duration : 0.6, 
            delay : 0.4
        }
    }, 
    initial : {
        y : 1000, 
        opacity : 0
    }
}

export const branche_scores = {
    exit : {
        y : 50, 
        x : '-50%', 
        opacity : 0
    }, 
    animate : {
        y : 0, 
        x : '-50%', 
        opacity : 1, 
        transition : {
            type : 'linear',
            duration : 0.6, 
            delay : 0.8
        }
    }, 
    initial : {
        y : 50, 
        x : '-50%', 
        opacity : 0
    }
}


//CALENDAR

export const title_calendar = {
    exit : {
        y : -50, 
        opacity : 0
    }, 
    animate : {
        y : 0, 
        opacity : 1, 
        transition : {
            duration : 0.8, 
            delay : 0.2
        }
    }, 
    initial : {
        y : -50, 
        opacity : 0
    }
}


export const box_calendar = {
    exit : {
        scale : 0
    }, 
    animate : {
        scale : 1
    }, 
    initial : {
        scale : 0
    }
}


export const branche_calendar = {
    exit : {
        y : 50, 
 
        opacity : 0
    }, 
    animate : {
        y : 0, 

        opacity : 1, 
        transition : {
            type : 'linear',
            duration : 0.6, 
            delay : 0.4
        }
    }, 
    initial : {
        y : 50, 

        opacity : 0
    }
}


//25 

export const letter_session = {
    exit : {
        y : 50, 
        opacity : 0
    }, 
    animate : {
        y : 0, 
        opacity : 1, 
        transition : {
            duration : 0.4, 
            delay : 0.5
        }
    }, 
    initial : {
        y : 50, 
        opacity : 0
    }
}