import {React, useState, useEffect} from 'react'
import {motion} from 'framer-motion'
import { table_scores, title_scores } from '../../../animations/session';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Popup from "../../../tools/Popup";
import axios from 'axios';
import { route } from '../../../config';
import {ReactComponent as Crown1} from '../../../assets/crown_1.svg'



export default function Activity(props) {
    /* 
    2 modes : 
        - day : get all dates to select
        - selected : get slected date info 
    */
    const [tools, setTools] = useState({
        mode : 'days', 
        users : [], 
    })

    useEffect(() => {
        if(tools.users.length === 0 ) {
            axios.get(route('game/users'))
            .then(res => {

                const transformedData = res.data.map(element => {
                    return {
                        ...element, 
                        results : JSON.parse(element.results)
                    }
                })

                setTools(state => {
                    return {
                        ...state, 
                        users : transformedData
                    }
                })


            })
            .catch(err => {
                console.log(err)
            })
        }
    }, [])

    const thead_structure = [
        {
            name : "day", 
            value : "Jour"
        }, 
        {
            name : "response", 
            value : "Réponse"
        }, 
        {
            name : "rate", 
            value : "Réussite"
        }, 
    ]

    const getRate = (id_game, id_result, users) =>{
        return users.map(user => {
            const result = user.results
            
            if(result.find(element => element.idUUID === id_game)) {
                if(result.find(element => element.idUUID === id_game).response === id_result) {
                    return true
                }
                return false
            }


            return false
        }).filter(element => element === true).length
    }

    const getDays = () => {
        return props.calendar.map(element => {
            var userDay = tools.users.find(subEl => subEl.idUUID === element.idgamer)
            var firstname = userDay.firstname
            var lastname = userDay.lastname
            var rate = getRate(element.idUUID, element.idgamer, tools.users)

            /*
                Récupérez le taux de réussite du jour

                

                props.
            */
            
            return {
                day : element.day, 
                response : `${firstname.toLowerCase()} ${lastname.toLowerCase()}`, 
                rate : rate
            }
        }).sort((a,b)=>a.day-b.day).slice(0, parseInt(props.currentDate) - 1)
    }

    const handleChangeMode = () => {
        setTools(state => {
            return {
                ...state, 
                mode : tools.mode === "days" ? "selected" : "days"
            }
        })
    }


    
    //console.log(tools.users)
    /*
        2 modes : 
            - days :
            - selected : 
            --> Créer un tab thead

            
    */



    return (
        <Popup
            handleClose = {() => props.setActivity(!props.activity)}
        >
            <div className="activity">
                <motion.h1
                    exit = "exit"
                    animate = "animate" 
                    initial = "initial"
                    variants = {title_scores}
                >
                    Analyse des résultats
                </motion.h1>
                <motion.div
                    exit = "exit"
                    animate = "animate" 
                    initial = "initial"
                    variants = {table_scores}
                >

                    <div className='table'>
                        <div 
                            className={tools.mode === "days" ? "thead thead__days" : "thead thead__days"}
                        >
                            {
                                thead_structure.map(element => (
                                    <div
                                        key = {JSON.stringify(element)}
                                    >
                                        {element.value}
                                    </div>
                                ))
                            }



                        </div>
                        <div className='tbody'>
                        <Scrollbars className='scrollContent'>
                        {
                            tools.users.length > 0 ?
                                getDays().map(element => (
                                    <div
                                        key = {JSON.stringify(element)}
                                    >
                                        <div>
                                            <div className='crown'><Crown1 /></div>
                                            {element.day}
                                        </div>

                                        <div>
                                            {element.response}
                                        </div>

                                        <div
                                            onClick = {handleChangeMode}
                                        >
                                            {
                                                tools.mode === "days" ? 
                                                    `${element.rate}/${tools.users.length}`
                                                :
                                                    `${(element.rate * 100 / tools.users.length).toFixed(0)}%`
                                            }
                                            
                                        </div>
                                    </div>
                                ))
                            : 
                                <div></div>
                                
                            }
                            </Scrollbars>
                        </div>
                    </div>

                </motion.div>



                
            </div>
        </Popup>
    )
}
